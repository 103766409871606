<template>
  <link rel="stylesheet" href="/css/common.css" type="text/css">
  <div class="whole-area">
    <router-view/>
  </div>
</template>
<script>

</script>

